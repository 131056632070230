@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background-color: #091615;
    font-family: 'Montserrat', sans-serif;
}

.noise::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    content: '';
    opacity: .1;
    pointer-events: none;
    background: url('../src/Assets/capa.png');
    z-index: 5;

}

.container-filter-0 {
    filter: opacity(1);
}

.video-bg{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 20;
    left: 0;
   
}

.carousel-status{
    display: none;
}

.slide img{
    width: 60% !important;
}

.zindex{
    z-index: 100;
}

.zindexnavbar{
    z-index: 1000;
}

